<template>
  <div class="gcash">
    <van-nav-bar
      title="Gcash"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="container box-show-0">
      <van-field v-model="balance" :label="$t('RECHARGEMONEY')" :placeholder="$t('RECHARGEMONEY')">
        <template #button>(PhP)</template>
      </van-field>
      <div class="balance-container">
        <van-row type="flex" justify="space-between">
          <van-col span="8" v-for="(item, index) in list" :key="index" >
            <van-button color="#21A0FF" type="primary" size="mini" round @click="balance = item.code">{{ item.code }}</van-button>
          </van-col>
        </van-row>
      </div>
    </div>
    <van-button color="#21A0FF" @click="sub" class="password" type="primary" block>{{$t('SUBMIT')}}</van-button>
  </div>
</template>

<script>
import { payment } from '@/api/my'
import { Toast } from 'vant'
const wanUnit = 1000
export default {
  name: 'gcash',
  data () {
    return {
      balance: '',
      list: {
        WAN: {
          code: 1 * wanUnit
        },
        ONE_BAI_WAN: {
          code: 5 * wanUnit
        },
        FIVE_BAI_WAN: {
          code: 10 * wanUnit
        }
      }
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async sub () {
      const { data } = await payment({
        amount: this.balance
      })
      if (data.code !== 200) {
        Toast(data.message)
      } else {
        location.href = data.data
      }
    }
  }
}
</script>

<style scoped>
.gcash >>> .van-nav-bar {
  background-color: #21A0FF;
  color: #fff;
}
.gcash >>> .van-nav-bar__title {
  color: #fff;
}
.password {
  width: 80%;
  margin: 20px auto;
  background-color: #00BFFF;
}
.container {
  /* margin: 20px 10px 0 10px; */
  /* padding: 10px; */
  border-radius: 12px;
}
.balance-container >>> .van-button {
  padding: 0 10px;
  color: #fff !important;
}
.balance-container >>>.van-col {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>
